@import "~react-image-gallery/styles/css/image-gallery.css";
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.static-text img{
  display: block;
  /* background-color: aqua; */
}

.static-text p:has(> img){
  display: flex;
  justify-content: center;
  align-items: center;
}

strong {
  font-size: 18px;
}
.chatRadio {
  display: none;
}
.chatLabel {
  display: block;
  cursor: pointer;
  margin: 5px;

  /* background-color: blue; */
}
.audioBtn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.staticConditions img{
  max-width: -webkit-fill-available;
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
    display: none;
}

.msgReached {
  background-color: aliceblue;
  border-radius: 20px;
}

/* .rental-price-radio{
  background-color: white;
} */

.rental-price-radio {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.rental-price-radio.active {
  background-color: #334660 !important;
  color: white !important;
}

.dynamicSelectClass {
  padding: 1px;
}

.width-full {
  width: 50%;
}

@media screen and (max-width: 900px) {
  .width-full {
    width: 100%;
  }
}

.set {
  padding: 16px;
}

@media screen and (max-width: 600px) {
  .carousel-margin {
    margin-top: 20px;
  }
}

.btn-hover-clear:hover {
  background-color: #4C7EE8 !important;
  color: white !important;
}
@media screen and (max-width: 990px) {
  .clearButton {
    position: absolute !important;
    bottom: 10px;
    /* left: 20px; */
  }
}

/* tabIndex -1 */

/* div[tabindex="-1"] {
  z-index: 99999 !important;
} */

.__indicator-separator {
  display: none;
}
.selectCustom {
  z-index: 999 !important;
  color: unset !important;
}
.__menu {
  width: max-content !important;
  z-index: 99999 !important;
}
/* .selectCustom .__control  {
  height: 45px;
  border: 1px solid #989898 !important;
  background: transparent;
} */
.selectCustom .__dropdown-indicator svg{
  display: none;
}

.selectCustom .__value-container {
  padding-inline: 0px !important;
}
.__group-heading {
  font-size: 16px !important;
}
.__placeholder {
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .owl-nav {
    display: none !important;
  }
}



.slides::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  height: 0;
}
.__menu-list::-webkit-scrollbar {
  width: 5px;  /* Remove scrollbar space */
  height: 5px;
}
/* Track */
.__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.__menu-list::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}

.thinScroll {
  overflow-y: scroll;
}
.thinScroll::-webkit-scrollbar {
  width: 5px;  /* Remove scrollbar space */
  height: 5px;
}
/* Track */
.thinScroll::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.thinScroll::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}




.highDiff {
  position: absolute;
  top: -65px;
  right: 0px;
}

.highDiffRtl {
  right: unset !important;
  left: 0px !important;
}

@media screen and (max-width: 900px) {
  .highDiff {
    top: 55px;
  }
}

.rentPriceMargin {
  margin-left: 160px;
}

@media screen and (max-width: 600px) {
  .rentPriceMargin {
    margin-left: 0px;
  }
}

.custom-items {
  position: relative;
  font-family: Arial;
}

.planCard {
  /* border: 1px solid black; */
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 10px 10px #ebebeb;
}
.planCardDescription {
  background-color: #e5eeff;
  border-radius: 5px;
  padding: 10px 5px !important;
  height: 130px;
}
.planCardPrice {
  margin-top: 10px;
}

@media screen and (max-width: 500px) {
  .cngplanNew {
    position: unset !important;
    display: block;
    width: 100% !important;
    margin-top: 15px;
  }
}



.articleImages img {
    /* max-width: 70% !important; */
    /* border-radius: 10px; */
    padding-top: 30px;
    
  }
  .articleImages p {
    display: flex;
    flex-direction: column;
    
  }

.articleName {
  font-size: 45px !important;
  text-align: center;
  font-weight: 100;
  max-width: 100% !important;
}

.articleImages p {
  width: 90%;
  height: 100%;
  object-fit: cover;
}
.articleBanner {
  max-width: 100% !important;
}
@media screen and (max-width: 500px) {
  .articleImages p {
    width: 100%;
  }
  .articleName {
    font-size: 25px !important;
    text-align: center;
  }
}

.saveShare {
  top: 529px !important;
}
@media screen and (min-width: 1600px) {
  .saveShare {
    top: 545px !important;
  }
}

@media screen and (max-width: 990px) {
  .saveShare > li {
    min-width: unset !important;
  }
  .saveShare > button > li {
    min-width: unset !important;
  }
  .saveShare .loader {
    width: 48px !important;
  }
}
@media screen and (max-width: 500px) {
  .usedNewTabs {
    justify-content: space-around;
  }
}

@media screen and (max-width: 1000px) {
  .topFourMob {
    height: 50px !important;
  }
}
@media screen and (max-width: 1000px) {
  .showMoreBrands {
    height: 40px !important;
  }
}

.searchedBar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  overflow-x: auto;
}
@media screen and (max-width: 500px) {
  .cardSmall {
    margin-bottom: 0px !important;
    margin-top: 3px !important;
  }
}
@media screen and (max-width: 500px) {
  .cardSmallSaved {
    margin-block: -15px !important;
  }
}
.compareCarousel {
  flex-wrap: unset !important;
  max-width: 100% !important;
  overflow-x: scroll !important;
}

.toaster_en {
  background-color: #33475f;
  margin: 0px;
  text-align: left;
  font-size: 12px;
}
.toaster_ar {
  background-color: #33475f;
  margin: 0px;
  text-align: right;
  font-size: 12px;
}
.Toastify__toast {
  background-color: #33475f !important;
}
.smallScreenFont {
  font-size: 20px;
}
@media screen and (max-width: 500px) {
  .smallScreenFont {
    font-size: 14px;
  }
}

.imageViewer > div > div > div > img {
  object-fit: contain;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  max-height: unset !important;
}

.IV_ar .react-simple-image-viewer__next {
  left: 0px !important;
  transform: rotate(180deg);
  width: 50px;
  float: left;
}
.IV_ar .react-simple-image-viewer__previous {
  right: 0px !important;
  transform: rotate(180deg);
  width: 50px;
  float: right
}

h6.back > a > img:hover {
  box-shadow: 0px 0px 4px rgb(0 0 0 / 12%);
}
@media screen and (max-width: 575px) {
  h6.back2 {
    margin-inline: 12px !important;
  }
}

.paginationButtonAr > li.next {
  transform: rotate(0deg) !important;
}
.paginationButtonAr > li.previous {
  transform: rotate(180deg) !important;
}

.articleOwl .owl-nav button.owl-next {
  left: unset;
  right: -45px;
}
.articleOwl .owl-nav button.owl-prev {
  right: unset;
  left: -45px;
}
.articleOwlRtl .owl-nav button.owl-next {
  left: -45px;
  right: unset;
  transform: rotate(180deg);
}
.articleOwlRtl .owl-nav button.owl-prev {
  right: -45px;
  left: unset;
  transform: rotate(180deg);
}

.markMain:hover {
  border: 1px solid blue !important;
  border-radius: 5px;
}

@media screen and (max-width: 500px) {
  .closed {
    position: unset !important;
    margin-top: 5px;
  }
}
.hed {
  text-align: center !important;
}

.stats {
  width: 60%;
}
.stats > div > div {
  background-color: #F9F9F9;
  height: 90px;
  padding: 5px;
}
.stats > div > div > div {
  padding: 10px;
  border-radius: 2px;
}
/* .stats > div > div > div:hover {
  background-color: #D1E0FF;
} */
@media screen and (max-width: 990px) {
  .stats {
    width: 100% !important;
  }
  .stats > div > div {
    border-radius: 8px !important;
  }
  .stats > div > div > div  {
    border: none !important;
  }
}

.themeButton {
  min-width: 123px;
  height: 45px;
  background-color: #4C7EE8;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  /* line-height: 45px; */
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  border: none
}

.invertButtons > div > button.owl-next{
  /* background-color: red !important; */
  right: unset;
  left: 0px;
  transform: rotate(180deg);
}
.invertButtons > div > button.owl-prev{
  /* background-color: red !important; */
  left: unset;
  right: 0px;
  transform: rotate(180deg);
}

/* .hoverUnderline:hover {
  text-decoration: underline;
} */
.heartIcon {
  height: 15px !important;
}
.heartIcon2 {
  height: 22px !important;
}

.priceBoxErrors {
  top: 37px !important;
  left: -10px !important;
  margin-left: 12px !important;
}
.sharePopover .popover {
  max-width: unset !important;
}
@media screen and (max-width: 580px) {
  .sellbrandIcon {
    width: unset !important;
    height: 170px !important;
  }
}

/* .basic-multi-select {
  width: 175px;
} */
.navTabsSortBy {
  top: 0px !important;
}
@media screen and (max-width: 990px) {
  .navTabsSortBy {
    top: -10px !important;
  }
}

.imgGallOriginal {
  background-color: red;
}
.image-gallery {
  width: 100%;
}
.image-gallery-image {
  /* height: -webkit-fill-available; */
}

.fuel-new input {
  height: 18px;
  width: 18px;
}
.fuel-new label {
  vertical-align: text-bottom;
}
@media screen and (max-width: 590px){
  .priceClass {
    font-size: 22px !important;
  }
}
.my-radio {
  display: flex;
  font-size: 17px;
  align-items: center;
}
.my-radio-icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 2px;
  overflow: hidden;
  border: 1px solid #4C7EE8;
}
.my-radio-icon-inner {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.my-radio-active {
  background-color: #4C7EE8;
}

video::-webkit-media-controls-panel {
  background-image: linear-gradient(transparent, #565656) !important;
}

.imgRTL {
  transform: scaleX(-1);
}

@media screen and (max-width: 575px) {
  .movePending {
    top: 40px !important;
    font-size: 11px;
  }
}

#phone-input-custom-wrapper * {
  position: absolute;
}

#phone-input-custom-wrapper label {
  z-index: 99;
  line-height: 25px;
  padding: 2px;
  margin-left: 5px;
}

#phone-input-custom-wrapper input {
  text-indent: 35px;
}


.paymentECheck iframe #root {
  background-color: red !important;
}

.Input-empty {
  background-color: red !important;
}

.customPhoneInput {
  text-indent: 85px;
}
.apexcharts-xaxis {
  direction: ltr;
}
.helperText {
  width: 100%
}
body.English .helperText {
  text-align: left;
}
body.Arabic .helperText, body.Kurdish .helperText {
  text-align: right;
}
@media screen and (max-width: 770px) {
  .encloseBorder {
    border: 1px solid #ccc !important;
    border-radius: 11px !important;
  }
  .intype {
    margin-bottom: 10px !important;
    padding-left: 50px !important;
  }
  /* body.English .intype button {
    left: 10px !important;
    right: unset;
  }
  body.Arabic .intype button, .Kurdish .intype button {
    left: unset;
    right: 10px !important;
  } */
}

.veriphyPhoneWarning {
  position: absolute;
  top: 60px;
}

@media screen and (min-width: 575px) {
  .veriphyPhoneWarning{
    top: 0px;
  }
}
.disabledButton {
  background-color: #D1E0FF !important;
}